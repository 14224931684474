.nyttarsaften {
  text-align: center;
  background-color: black;
}

.nyttarsaften-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(15px + 2vmin);
  color: white;
  z-index: 1;
  mix-blend-mode: color-dodge;
  opacity: 0;
  animation: fadeIn ease-in 2s;
  animation-fill-mode:forwards;
  animation-delay: 1s;
}

.nyttarsaften-header:hover {
  mix-blend-mode: exclusion;
}

.nyttarsaften p {
  opacity: 0;
  animation: fadeIn ease-in 2s;
  animation-fill-mode:forwards;
  animation-delay: 3s;
}

@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
